import React, { useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Flex } from "@nubeteck/components";
import { AcademicRecordContainer } from "./report-container.styled";
import { Empty, Form, Spin } from "antd";
import {
  FlexPDFButtonsStyled,
  SpinnerContainerStyled,
} from "src/pages/student-pages/cart/cart.styled";
import {
  ButtonAlternativeStyled,
  ButtonPrimaryStyled,
} from "src/components/custom/buttons/buttons.styled";
import { useGetReportByIdMutation } from "src/services";
import ConfigurationInput from "src/pages/admin-pages/generalSettings/settingsInput";
import { IReport } from "src/Interfaces/reports";
import { FlexStyled } from "src/components/registration/register-confirmation/confirmation.styled";
import { getFormData } from "src/utils/files-util";
import { useMediaQuery } from "src/hooks";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`;

function ReportContainer({ reporte }: { reporte: IReport | undefined }) {
  const [form] = Form.useForm<any>();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [getReport, { data: report, isLoading: isLoadingReport, error: errorReport }] =
    useGetReportByIdMutation();
  const [PDFPages, setPDFPages] = React.useState<number>(0);
  const [keepRendering, setKeepRendering] = React.useState<number>(1);

  const generateReport = () => {
    form
      .validateFields(reporte?.parametros?.map((pa) => pa.reporteParametroNombre))
      .then((values) => {
        if (values) {
          getReport({ reporteId: reporte?.reporteId, values: getFormData(values) });
        }
      });
  };

  const onDocLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPDFPages(numPages);
  };

  const getPdfPages = () => {
    const pages: JSX.Element[] = [];
    for (let index = 1; index <= PDFPages; index++) {
      pages.push(
        <Page
          pageNumber={index}
          width={window.innerWidth / 1.6}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          renderMode="canvas"
        />,
      );
    }
    return pages;
  };

  useEffect(() => {
    setPDFPages(0);
    reporte?.parametros?.forEach((field) => {
      form.setFieldValue(field.reporteParametroNombre, field.valor);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reporte]);

  const getParameterOptions = (opciones: any[] | null, parametroFiltroId: number) => {
    const parametroReferenciaNombre = reporte?.parametros?.find(
      (p) => p.reporteParametroId === parametroFiltroId,
    )?.reporteParametroNombre;
    const parametroReferenciaValor = parametroReferenciaNombre
      ? form.getFieldValue(parametroReferenciaNombre)
      : null;

    const opcionesCascada = parametroReferenciaValor
      ? opciones?.filter((op) => op.filtro === parametroReferenciaValor)
      : opciones;

    return opcionesCascada && opcionesCascada?.length > 0
      ? opcionesCascada?.map((op) => ({ opcion: op.opcion, id: op.opcion }))
      : [];
  };

  return reporte?.reporteId ? (
    <>
      <Form form={form}>
        <Flex
          style={{
            gap: "15px",
            alignItems: "baseline",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <FlexStyled
            style={{ gap: isMobile ? 0 : 12, marginTop: isMobile ? 0 : 50, flexWrap: "wrap" }}
          >
            {keepRendering
              ? reporte?.parametros?.map((field) => {
                  return (
                    <Form.Item
                      key={field.reporteParametroId}
                      rules={[{ required: field.reporteParametroRequerido }]}
                      label={field.reporteParametroCampo}
                      name={field.reporteParametroNombre}
                    >
                      <ConfigurationInput
                        data={{
                          id: field.reporteParametroId,
                          label: field.reporteParametroCampo,
                          nombre: field.reporteParametroNombre,
                          descripcion: field.reporteParametroDescripcion,
                          opciones: getParameterOptions(
                            field.opciones,
                            field.parametroReporteFiltroId,
                          ),
                          tipo: field.reporteParametroTipoNombre,
                          requerido: field.reporteParametroRequerido,
                          valor: field.valor,
                        }}
                        onChange={(value) => {
                          form.setFieldValue(field.reporteParametroNombre, value);
                          setKeepRendering(keepRendering + 1);
                        }}
                      />
                    </Form.Item>
                  );
                })
              : null}
          </FlexStyled>
          <FlexPDFButtonsStyled>
            <ButtonPrimaryStyled onClick={generateReport}>Generar reporte</ButtonPrimaryStyled>
            {PDFPages > 0 && (
              <ButtonAlternativeStyled
                loading={isLoadingReport}
                type="link"
                href={`data:application/pdf;base64,${report?.data}`}
                download={`Reporte ${reporte?.reporteNombre}`}
              >
                Descargar PDF
              </ButtonAlternativeStyled>
            )}
          </FlexPDFButtonsStyled>
        </Flex>
      </Form>
      <AcademicRecordContainer>
        {isLoadingReport && (
          <SpinnerContainerStyled>
            <Spin spinning={isLoadingReport} size="large" />
          </SpinnerContainerStyled>
        )}
        <Document
          file={`data:application/pdf;base64,${report?.data}`}
          onLoadSuccess={onDocLoadSuccess}
          loading={
            errorReport
              ? "Ha ocurrido un error generando el reporte."
              : !isLoadingReport
              ? (reporte?.parametros ?? []).length > 0
                ? "Complete los parametros para generar el reporte."
                : "Haga clík en Generar reporte"
              : "...Generando reporte"
          }
        >
          {getPdfPages()}
        </Document>
      </AcademicRecordContainer>
    </>
  ) : (
    <>
      <Empty description="Seleccione un reporte" />
    </>
  );
}

export default ReportContainer;
