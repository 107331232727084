import {
  Box,
  Button,
  Icon,
  Input,
  InputNumber,
  Upload,
  type IUploadProps,
  Tooltip,
  Tag,
} from "@nubeteck/components";
import { FormatterUtils, ParserUtils } from "@nubeteck/utils";
import { Alert, Form, Modal, Select } from "antd";
import React, { DetailedHTMLProps, HTMLAttributes, useEffect } from "react";

import {
  BillCardStyled,
  BillConceptStyled,
  BillFlexStyled,
  IconStyled,
  // IconStyled,
  InputContainerStyled,
  RedTextStyled,
  SmallHeadingStyled,
  TransferDataStyled,
} from "./bill.styled";
import { toast } from "react-hot-toast";
import {
  useGetCurrenciesQuery,
  useGetFinantialEntitiesQuery,
  useUploadFileMutation,
} from "src/services";
import { ICurrency, IFinantialEntity } from "src/Interfaces/options";
import { IItemsFacturacion } from "src/Interfaces/cart";
import { getBase64, getFormData } from "src/utils/files-util";
import { RcFile } from "antd/es/upload";
import {
  useGetIsTransferActiveMutation,
  useSaveTransferEndorsmentMutation,
} from "src/services/reviewsServices";
import useCalculateBill from "./hooks/useCalculateBill";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import TextArea from "antd/es/input/TextArea";

interface IFormBillProps {
  numero: string;
  montoTransferencia: number;
  monedaId: number;
  entidadFinancieraId: number;
  motivo: string;
  model: any;
  saldoAFavorAUsarColones: number;
  saldoAFavorAUsarDolar: number;
}

const Bill = ({
  conversion,
  billColones,
  isOnAdmin,
  billDolares,
  pagoCuotas,
  totalCuotas,
  setValue,
  limiteCuotas,
  totalPendienteColones,
  totalPendienteDolares,
  setTotalPendienteColones,
  setTotalPendienteDolares,
  setTotalAmountColones,
  setTotalAmountDolares,
  hayCargosEnDolares,
  hayCargosEnColones,
  actualizarPagosCuotas,
  dataTransferencia,
  hayMateriasOServicios,
}: {
  conversion: number;
  billColones: IItemsFacturacion;
  billDolares: IItemsFacturacion;
  isOnAdmin?: boolean;
  setValue: (obj: any) => void;
  limiteCuotas: number;
  pagoCuotas: boolean;
  totalCuotas: number;
  totalPendienteColones: number;
  totalPendienteDolares: number;
  hayCargosEnDolares: boolean;
  hayCargosEnColones: boolean;
  dataTransferencia: any;
  setTotalPendienteColones: (t: number) => void;
  setTotalPendienteDolares: (t: number) => void;
  setTotalAmountColones: (t: number) => void;
  setTotalAmountDolares: (t: number) => void;
  actualizarPagosCuotas: (monto: number, moneda: number) => void;
  hayMateriasOServicios: boolean;
}) => {
  const [form] = Form.useForm<IFormBillProps>();

  const { validateFields, setFieldValue } = form;
  const saldoParaPagar = Form.useWatch("saldoAFavorAUsarColones", form) ?? 0;
  const saldoParaPagarDolar = Form.useWatch("saldoAFavorAUsarDolar", form) ?? 0;
  const montoAhora = Form.useWatch("montoAhora", form) ?? 0;
  const montoAhoraDolares = Form.useWatch("montoAhoraDolares", form) ?? 0;
  const montoTransferencia = Form.useWatch("montoTransferencia", form) ?? 0;
  const monedaTransferencia = Form.useWatch("monedaId", form) ?? 0;

  const [isModalTransferenciaOpen, setIsModalTransferenciaOpen] = React.useState(false);
  const {
    totalLuego: totalLuegoDolar,
    totalDescontado: totalDescontadoDolar,
    subtotal: subtotalDolar,
    totalAhora: totalAhoraDolar,
    setTotalAhora: setTotalAhoraDolar,
  } = useCalculateBill(
    {
      ...billDolares,
      totalDescuentoBeca: billDolares.totalDescuentoBeca,
      totalDescuentoPatrocinio: billDolares.totalDescuentoPatrocinio,
    },
    totalPendienteDolares,
    totalCuotas,
    saldoParaPagarDolar,
    monedaTransferencia == 1 ? montoTransferencia : montoTransferencia / conversion,
    setTotalPendienteDolares,
    setTotalAmountDolares,
  );

  const { totalLuego, totalDescontado, subtotal, totalAhora, setTotalAhora } = useCalculateBill(
    {
      ...billColones,
      totalDescuentoBeca: billColones.totalDescuentoBeca,
      totalDescuentoPatrocinio: billColones.totalDescuentoPatrocinio,
    },
    totalPendienteColones,
    totalCuotas,
    saldoParaPagar,
    monedaTransferencia == 2 ? montoTransferencia : montoTransferencia * conversion,
    setTotalPendienteColones,
    setTotalAmountColones,
  );

  const { data: finantialEntities } = useGetFinantialEntitiesQuery("");
  const { data: currencies } = useGetCurrenciesQuery("");

  const [uploadFile, { isLoading: isLoadingFile }] = useUploadFileMutation();
  const [getIsTransferActive, { data: isTransferActive }] = useGetIsTransferActiveMutation();
  const [
    saveTransfer,
    { isError: isErrorTransfer, isSuccess: isSuccessTransfer, isLoading: isLoadingTransfer },
  ] = useSaveTransferEndorsmentMutation();

  useEffect(() => {
    getIsTransferActive();
  }, [getIsTransferActive]);

  useEffect(() => {
    if (pagoCuotas || billColones?.saldoNoVencido > 0 || billColones?.saldoVencido > 0) {
      if (montoAhora == undefined || montoAhora == "" || montoAhora == null)
        setFieldValue("montoAhora", 0);

      setTotalAhora(
        monedaTransferencia === 2 || monedaTransferencia == 0
          ? +montoAhora - montoTransferencia
          : +montoAhora - montoTransferencia * conversion,
      );
    }

    actualizarPagosCuotas(
      +montoAhora - totalPendienteColones + totalCuotas > 0
        ? +montoAhora - totalPendienteColones + totalCuotas
        : 0,
      2,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [montoAhora]);

  useEffect(() => {
    if (pagoCuotas || billDolares?.saldoNoVencido > 0 || billDolares?.saldoVencido > 0) {
      if (montoAhoraDolares == undefined || montoAhoraDolares == "" || montoAhoraDolares == null)
        setFieldValue("montoAhoraDolares", 0);

      setTotalAhoraDolar(
        monedaTransferencia === 1 || !monedaTransferencia
          ? +montoAhoraDolares - montoTransferencia
          : +montoAhoraDolares - montoTransferencia / conversion,
      );
    }
    // const posiblesCuotas = (totalPendienteDolares - +e / +e);

    actualizarPagosCuotas(
      +montoAhoraDolares - totalPendienteDolares + totalCuotas > 0
        ? +montoAhoraDolares - totalPendienteDolares + totalCuotas
        : 0,
      1,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [montoAhoraDolares]);

  useEffect(() => {
    const minimoPosible =
      totalPendienteColones - totalCuotas > 0
        ? (totalPendienteColones - totalCuotas)?.toFixed(2)
        : 0;
    setFieldValue("montoAhora", minimoPosible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPendienteColones, totalCuotas, totalDescontado]);

  useEffect(() => {
    const minimoPosible =
      totalPendienteDolares - totalCuotas > 0
        ? (totalPendienteDolares - totalCuotas)?.toFixed(2)
        : 0;
    setFieldValue("montoAhoraDolares", minimoPosible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPendienteDolares, totalCuotas, totalDescontadoDolar]);

  useEffect(() => {
    setValue({
      montoTransferencia: montoTransferencia ?? 0,
      monedaTransferencia: monedaTransferencia ?? 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [montoTransferencia, monedaTransferencia]);

  const handleCancelTransferencia = () => {
    setIsModalTransferenciaOpen(false);
    setFieldValue("montoTransferencia", 0);
    setFieldValue("motivo", null);
    setFieldValue("monedaId", null);
    setFieldValue("entidadFinancieraId", null);
    setFieldValue("numero", null);
    setFieldValue("model", null);
  };

  useEffect(() => {
    if (isErrorTransfer) {
      toast.error(
        "Ha habido un error guardando el aval de transferencia. Intente otra vez.",
        toastErrorStyle,
      );
      handleCancelTransferencia();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorTransfer]);

  useEffect(() => {
    if (isSuccessTransfer) {
      setIsModalTransferenciaOpen(false);
      toast.success("Aval de transferencia almacenado correctamente.", toastSuccessStyle);
    }
  }, [isSuccessTransfer]);

  const onChangeTransferencia = (info: any) => {
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj as RcFile, (url: any) => {
        setFieldValue("model", url);
      });
    }
  };

  const props: IUploadProps = {
    name: "model",
    multiple: false,
    maxCount: 1,
    beforeUpload: () => false,
    accept: ".pdf,.jpg,.jpeg,.png,.docx,.doc",
  };

  const propsNumbersSpan: DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> = {
    style: { textAlign: "right", width: "90px" },
  };

  const notify = () => {
    setIsModalTransferenciaOpen(true);
  };
  const handleOkTransferencia = () => {
    validateFields([
      "model",
      "montoTransferencia",
      "numero",
      "entidadFinancieraId",
      "monedaId",
      "motivo",
    ])
      .then((values) => {
        if (values) {
          uploadFile(getFormData({ model: values.model.file })).then((res: any) => {
            const archivo = res as { data: any };
            saveTransfer({
              archivoId: archivo?.data?.data,
              monto: values.montoTransferencia,
              motivo: values.motivo,
              entidadFinancieraId: values.entidadFinancieraId,
              numero: values.numero,
              monedaId: values.monedaId,
            });
          });
        }
      })
      .catch(() => {
        toast.error(`Algunos campos necesitan ser revisados.`);
      });
  };

  const getDuesMessage = (currencyId: 1 | 2) => {
    const totalPendiente = currencyId == 1 ? totalPendienteDolares : totalPendienteColones;
    const minimoPosible =
      totalPendiente - totalCuotas > 0 ? +(totalPendienteColones - totalCuotas)?.toFixed(2) : 0;
    let totalAPagarDeMas =
      currencyId == 1 ? montoAhoraDolares - minimoPosible : montoAhora - minimoPosible;
    const futurePayments: number[] = [];
    const symbol = currencyId == 1 ? "$" : "₡";

    const remaining =
      currencyId == 1 ? totalLuegoDolar + totalAPagarDeMas : totalLuego + totalAPagarDeMas;

    const dues = remaining / limiteCuotas;
    const duesRounded = Math.trunc(dues);
    const totalSpare = Math.abs(remaining - duesRounded * limiteCuotas);
    const valueIfHasDecimal = +(duesRounded + totalSpare).toFixed(0);

    for (let index = 0; index < limiteCuotas; index++) {
      if (index == 1 && totalSpare !== 0 && valueIfHasDecimal !== duesRounded) {
        futurePayments.push(duesRounded + totalSpare);
      } else futurePayments.push(duesRounded);
    }

    futurePayments?.forEach((fee: number, i) => {
      let saldo = fee;
      if (totalAPagarDeMas > 0) {
        saldo = saldo - totalAPagarDeMas;
        if (saldo < 0) {
          totalAPagarDeMas = saldo * -1;
          saldo = 0;
        } else {
          totalAPagarDeMas = 0;
        }
      }
      futurePayments[i] = saldo;
    });

    const futurePaymentsResult = futurePayments
      .filter((val) => val > 0)
      .sort()
      .reverse();
    const result: any = {};
    futurePaymentsResult.forEach((val) => {
      result[val] = (result[val] || 0) + 1;
    });

    let message = "";
    Object.keys(result).forEach((key) => {
      message += `${result[key]} cuota(s) de ${symbol} ${FormatterUtils.number(+key)}; `;
    });

    return message;
  };

  return (
    <Form layout="vertical" form={form}>
      <BillCardStyled>
        <BillFlexStyled>
          <h4>Saldos:</h4>
          {hayCargosEnColones && <h4 style={{ width: "90px", textAlign: "center" }}>Colones:</h4>}
          {hayCargosEnDolares && <h4 style={{ width: "90px", textAlign: "center" }}>Dolares:</h4>}
        </BillFlexStyled>

        <Box>
          <BillFlexStyled>
            <BillConceptStyled>
              Saldo a favor:{" "}
              <Tooltip
                placement="topLeft"
                color="#006AEA"
                title="Fondos excedentes de pagos previos, utilizable para futuros pagos o reembolso. Puede usarlo en este pago."
              >
                <IconStyled name="" colorPrimary />
              </Tooltip>
            </BillConceptStyled>
            {hayCargosEnColones && (
              <span {...propsNumbersSpan}>
                ₡ {FormatterUtils.number(`${billColones?.saldoAFavor}`)}
              </span>
            )}
            {hayCargosEnDolares && (
              <span {...propsNumbersSpan}>
                $ {FormatterUtils.number(`${billDolares?.saldoAFavor}`)}
              </span>
            )}
          </BillFlexStyled>

          <BillFlexStyled>
            <RedTextStyled>
              Saldo vencido/pendiente:{" "}
              <Tooltip
                placement="topLeft"
                color="#ac0000"
                title="Deuda pendiente ya vencida con la universidad. Representa la deuda por pagos no realizados."
              >
                <IconStyled name="" />
              </Tooltip>
            </RedTextStyled>
            {hayCargosEnColones && (
              <RedTextStyled {...propsNumbersSpan}>
                ₡ {FormatterUtils.number(`${billColones?.saldoVencido}`)}
              </RedTextStyled>
            )}
            {hayCargosEnDolares && (
              <RedTextStyled {...propsNumbersSpan}>
                $ {FormatterUtils.number(`${billDolares?.saldoVencido}`)}
              </RedTextStyled>
            )}
          </BillFlexStyled>

          <BillFlexStyled>
            <BillConceptStyled>
              Saldo no vencido:{" "}
              <Tooltip
                placement="topLeft"
                color="#006AEA"
                title="Deuda pendiente sin vencer con la universidad. Representa las cuotas de cargos por pagar."
              >
                <IconStyled name="" colorPrimary />
              </Tooltip>
            </BillConceptStyled>
            {hayCargosEnColones && (
              <span {...propsNumbersSpan}>
                ₡ {FormatterUtils.number(`${billColones?.saldoNoVencido?.toFixed(2)}`)}
              </span>
            )}
            {hayCargosEnDolares && (
              <span {...propsNumbersSpan}>
                $ {FormatterUtils.number(`${billDolares?.saldoNoVencido?.toFixed(2)}`)}
              </span>
            )}
          </BillFlexStyled>
        </Box>

        <Box>
          <h4>Resumen:</h4>
          <BillFlexStyled>
            <BillConceptStyled>Total materias:</BillConceptStyled>
            {hayCargosEnColones && (
              <span {...propsNumbersSpan}>
                ₡ {FormatterUtils.number(`${billColones?.costoTotalAsignaturas?.toFixed(2)}`)}
              </span>
            )}
            {hayCargosEnDolares && (
              <span {...propsNumbersSpan}>
                $ {FormatterUtils.number(`${billDolares?.costoTotalAsignaturas?.toFixed(2)}`)}
              </span>
            )}
          </BillFlexStyled>
          <BillFlexStyled>
            <BillConceptStyled>+ Otros cargos:</BillConceptStyled>
            {hayCargosEnColones && (
              <span {...propsNumbersSpan}>
                ₡ {FormatterUtils.number(`${billColones?.costoTotalOtrosCargos?.toFixed(2)}`)}
              </span>
            )}
            {hayCargosEnDolares && (
              <span {...propsNumbersSpan}>
                $ {FormatterUtils.number(`${billDolares?.costoTotalOtrosCargos?.toFixed(2)}`)}
              </span>
            )}
          </BillFlexStyled>
          <BillFlexStyled>
            <BillConceptStyled>+ IVA:</BillConceptStyled>
            {hayCargosEnColones && (
              <span {...propsNumbersSpan}>
                ₡ {FormatterUtils.number(`${billColones?.totalIva?.toFixed(2)}`)}
              </span>
            )}
            {hayCargosEnDolares && (
              <span {...propsNumbersSpan}>
                $ {FormatterUtils.number(`${billDolares?.totalIva?.toFixed(2)}`)}
              </span>
            )}
          </BillFlexStyled>
        </Box>

        <Box>
          <BillFlexStyled>
            <SmallHeadingStyled>SUBTOTAL:</SmallHeadingStyled>
            {hayCargosEnColones && (
              <SmallHeadingStyled {...propsNumbersSpan}>
                ₡ {FormatterUtils.number(subtotal?.toFixed(2))}
              </SmallHeadingStyled>
            )}
            {hayCargosEnDolares && (
              <SmallHeadingStyled {...propsNumbersSpan}>
                $ {FormatterUtils.number(subtotalDolar?.toFixed(2))}
              </SmallHeadingStyled>
            )}
          </BillFlexStyled>
          <BillFlexStyled>
            <BillConceptStyled>(-) Beca(s):</BillConceptStyled>
            {hayCargosEnColones && (
              <span {...propsNumbersSpan}>
                ₡ {FormatterUtils.number(`${billColones?.totalDescuentoBeca?.toFixed(2)}`)}
              </span>
            )}
            {hayCargosEnDolares && (
              <span {...propsNumbersSpan}>
                $ {FormatterUtils.number(`${billDolares?.totalDescuentoBeca?.toFixed(2)}`)}
              </span>
            )}
          </BillFlexStyled>
          <BillFlexStyled>
            <BillConceptStyled>(-) Patrocinio:</BillConceptStyled>
            <>
              {hayCargosEnColones && (
                <span {...propsNumbersSpan}>
                  ₡ {FormatterUtils.number(`${billColones?.totalDescuentoPatrocinio?.toFixed(2)}`)}
                </span>
              )}
              {hayCargosEnDolares && (
                <span {...propsNumbersSpan}>
                  $ {FormatterUtils.number(`${billDolares?.totalDescuentoPatrocinio?.toFixed(2)}`)}
                </span>
              )}
            </>
          </BillFlexStyled>
          <BillFlexStyled></BillFlexStyled>
          {((billColones?.saldoAFavor > 0 && hayCargosEnColones) ||
            (billDolares?.saldoAFavor > 0 && hayCargosEnDolares)) && (
            <BillFlexStyled>
              <BillConceptStyled>(-) Saldo a favor:</BillConceptStyled>
              {billColones?.saldoAFavor > 0 && hayCargosEnColones && (
                <Form.Item name="saldoAFavorAUsarColones" style={{ margin: 0 }} help="Monto a usar">
                  <InputNumber
                    onChange={(e) => {
                      const value = e !== null ? +e : 0;
                      setValue({ saldoAFavorAUsarColones: value });

                      actualizarPagosCuotas(
                        value - billColones?.costoTotal > 0 ? value - billColones?.costoTotal : 0,
                        2,
                      );
                    }}
                    style={{ border: "1px solid #00479B" }}
                    max={
                      billColones?.saldoAFavor <
                      billColones?.costoTotal + billColones?.saldoNoVencido
                        ? billColones?.saldoAFavor
                        : billColones?.costoTotal + billColones?.saldoNoVencido
                    }
                    min={0}
                    controls={false}
                    formatter={(value) => FormatterUtils.number(value)}
                    parser={(value) => ParserUtils.number(value)}
                  />
                </Form.Item>
              )}
              {billDolares?.saldoAFavor > 0 && hayCargosEnDolares && (
                <Form.Item name="saldoAFavorAUsarDolar" style={{ margin: 0 }}>
                  <InputNumber
                    style={{ border: "1px solid #00479B" }}
                    onChange={(e) => {
                      const value = e !== null ? +e : 0;
                      setValue({ saldoAFavorAUsarDolar: value });

                      actualizarPagosCuotas(
                        value - billDolares?.costoTotal > 0 ? value - billDolares?.costoTotal : 0,
                        1,
                      );
                    }}
                    max={
                      billDolares?.saldoAFavor <
                      billDolares?.costoTotal + billDolares?.saldoNoVencido
                        ? billDolares?.saldoAFavor
                        : billDolares?.costoTotal + billDolares?.saldoNoVencido
                    }
                    min={0}
                    controls={false}
                    formatter={(value) => FormatterUtils.number(value)}
                    parser={(value) => ParserUtils.number(value)}
                  />
                </Form.Item>
              )}
            </BillFlexStyled>
          )}

          <BillFlexStyled>
            <SmallHeadingStyled>Total pagado:</SmallHeadingStyled>
            {hayCargosEnColones && (
              <SmallHeadingStyled {...propsNumbersSpan}>
                ₡ {FormatterUtils.number(`${totalDescontado?.toFixed(2)}`)}
              </SmallHeadingStyled>
            )}
            {hayCargosEnDolares && (
              <SmallHeadingStyled {...propsNumbersSpan}>
                $ {FormatterUtils.number(`${totalDescontadoDolar?.toFixed(2)}`)}
              </SmallHeadingStyled>
            )}
          </BillFlexStyled>
          <BillFlexStyled style={{ marginTop: "-20px" }}>
            <SmallHeadingStyled>Pendiente:</SmallHeadingStyled>
            {hayCargosEnColones && (
              <SmallHeadingStyled {...propsNumbersSpan}>
                ₡ {FormatterUtils.number(`${totalPendienteColones?.toFixed(2)}`)}
              </SmallHeadingStyled>
            )}
            {hayCargosEnDolares && (
              <SmallHeadingStyled {...propsNumbersSpan}>
                $ {FormatterUtils.number(`${totalPendienteDolares?.toFixed(2)}`)}
              </SmallHeadingStyled>
            )}
          </BillFlexStyled>
          <BillFlexStyled>
            <SmallHeadingStyled>
              {pagoCuotas ? "TOTAL A PAGAR AHORA: " : "TOTAL A PAGAR: "}
            </SmallHeadingStyled>
            {hayCargosEnColones && (
              <SmallHeadingStyled {...propsNumbersSpan}>
                ₡{" "}
                {totalAhora + montoTransferencia > 0
                  ? FormatterUtils.number(
                      (monedaTransferencia === 2
                        ? totalAhora + montoTransferencia
                        : totalAhora + montoTransferencia * conversion
                      )?.toFixed(2),
                    )
                  : 0}
              </SmallHeadingStyled>
            )}
            {hayCargosEnDolares && (
              <SmallHeadingStyled {...propsNumbersSpan}>
                ${" "}
                {totalAhoraDolar + montoTransferencia > 0
                  ? FormatterUtils.number(
                      (monedaTransferencia === 1
                        ? totalAhoraDolar + montoTransferencia
                        : totalAhoraDolar + montoTransferencia / conversion
                      )?.toFixed(2),
                    )
                  : 0}
              </SmallHeadingStyled>
            )}
          </BillFlexStyled>
        </Box>
        <Box>
          <Box>
            {pagoCuotas ||
            billColones?.saldoNoVencido > 0 ||
            billDolares?.saldoNoVencido > 0 ||
            billColones?.saldoVencido > 0 ||
            billDolares?.saldoVencido > 0 ? (
              <>
                {pagoCuotas && (
                  <BillFlexStyled>
                    <SmallHeadingStyled>Total a pagar en cuotas:</SmallHeadingStyled>
                    {hayCargosEnColones && (
                      <SmallHeadingStyled {...propsNumbersSpan}>
                        ₡ {FormatterUtils.number(`${totalCuotas?.toFixed(2)}`)}
                      </SmallHeadingStyled>
                    )}
                    {hayCargosEnDolares && (
                      <SmallHeadingStyled {...propsNumbersSpan}>
                        $ {FormatterUtils.number(`${totalCuotas?.toFixed(2)}`)}
                      </SmallHeadingStyled>
                    )}
                  </BillFlexStyled>
                )}

                {hayCargosEnColones && (
                  <Form.Item
                    required
                    name="montoAhora"
                    help={
                      <div
                        style={{
                          maxWidth: hayCargosEnDolares ? "410px" : "250px",
                          color: "#949292",
                        }}
                      >
                        El monto digitado no puede ser menor a ₡
                        {hayMateriasOServicios && totalPendienteColones - totalCuotas > 0
                          ? FormatterUtils.number((totalPendienteColones - totalCuotas)?.toFixed(2))
                          : 0}
                        .
                        {pagoCuotas
                          ? " El exceso a su saldo no vencido disminuirá el monto de sus cuotas."
                          : null}
                      </div>
                    }
                    style={{ margin: "0 0 8px 0" }}
                    label="Monto en colones a pagar ahora"
                  >
                    <InputNumber
                      placeholder="Monto"
                      min={
                        hayMateriasOServicios
                          ? (totalPendienteColones - totalCuotas)?.toFixed(2)
                          : 0
                      }
                      // max={(totalPendienteColones + billColones?.saldoNoVencido)?.toFixed(2)}
                      controls={false}
                      formatter={(value) => FormatterUtils.number(value)}
                      parser={(value) => ParserUtils.number(value)}
                    />
                  </Form.Item>
                )}
                {hayCargosEnDolares && (
                  <Form.Item
                    required
                    name="montoAhoraDolares"
                    help={
                      <div style={{ maxWidth: hayCargosEnDolares ? "410px" : "250px" }}>
                        El monto digitado no puede ser menor a $
                        {hayMateriasOServicios && totalPendienteDolares - totalCuotas > 0
                          ? FormatterUtils.number((totalPendienteDolares - totalCuotas)?.toFixed(2))
                          : 0}
                        .
                        {pagoCuotas
                          ? " El exceso a su saldo no vencido disminuirá el monto de sus cuotas."
                          : null}
                      </div>
                    }
                    style={{ margin: "0 0 8px 0" }}
                    label="Monto en dólares a pagar ahora"
                  >
                    <InputNumber
                      placeholder="Monto"
                      min={
                        hayMateriasOServicios
                          ? (totalPendienteDolares - totalCuotas)?.toFixed(2)
                          : 0
                      }
                      // max={(totalPendienteDolares + billDolares?.saldoNoVencido)?.toFixed(2)}
                      controls={false}
                      formatter={(value) => FormatterUtils.number(value)}
                      parser={(value) => ParserUtils.number(value)}
                    />
                  </Form.Item>
                )}

                {(totalLuego || totalLuegoDolar) && totalCuotas > 0 ? (
                  <div
                    style={{
                      maxWidth: hayCargosEnDolares ? "410px" : "250px",
                      marginTop: "20px",
                    }}
                  >
                    Cuotas restantes estimadas: {hayCargosEnColones ? getDuesMessage(2) : null}
                    {hayCargosEnColones && hayCargosEnDolares && ",y"}
                    {hayCargosEnDolares ? getDuesMessage(1) : null}
                  </div>
                ) : null}
              </>
            ) : null}
          </Box>
        </Box>

        <Box>
          <br></br>
          {isTransferActive?.data && !isOnAdmin && (
            <BillFlexStyled>
              <Button
                style={{
                  padding: 5,
                  fontWeight: "bolder",
                  fontSize: 14.5,
                  color: "#006AEA",
                }}
                type={
                  montoTransferencia || (dataTransferencia?.monto && dataTransferencia?.monto > 0)
                    ? "link"
                    : "default"
                }
                onClick={() => {
                  !isSuccessTransfer && !(dataTransferencia?.monto && dataTransferencia?.monto > 0)
                    ? notify()
                    : null;
                }}
              >
                PAGO POR TRANSFERENCIA
              </Button>
              {montoTransferencia || (dataTransferencia?.monto && dataTransferencia?.monto > 0) ? (
                <TransferDataStyled>
                  {" "}
                  <SmallHeadingStyled {...propsNumbersSpan}>{` ${
                    monedaTransferencia === 1 ? "$" : "₡"
                  } ${FormatterUtils.number(
                    dataTransferencia?.monto && dataTransferencia?.monto > 0
                      ? dataTransferencia?.monto
                      : montoTransferencia,
                  )}`}</SmallHeadingStyled>
                  {(hayCargosEnColones && !hayCargosEnDolares && monedaTransferencia == 1) ||
                  (!hayCargosEnColones && hayCargosEnDolares && monedaTransferencia == 2) ? (
                    <Tag>Tasa: {conversion}</Tag>
                  ) : null}
                </TransferDataStyled>
              ) : null}
            </BillFlexStyled>
          )}
          <br />
          {montoTransferencia > 0 && (
            <BillFlexStyled>
              <SmallHeadingStyled>Total a pagar con tarjeta</SmallHeadingStyled>
              {hayCargosEnColones && (
                <SmallHeadingStyled {...propsNumbersSpan}>
                  ₡ {totalAhora > 0 ? FormatterUtils.number(totalAhora?.toFixed(2)) : 0}
                </SmallHeadingStyled>
              )}
              {hayCargosEnDolares && (
                <SmallHeadingStyled {...propsNumbersSpan}>
                  $ {totalAhoraDolar > 0 ? FormatterUtils.number(totalAhoraDolar?.toFixed(2)) : 0}
                </SmallHeadingStyled>
              )}
            </BillFlexStyled>
          )}
          <BillFlexStyled>
            {pagoCuotas && (
              <>
                <BillConceptStyled>Total para pagar luego :</BillConceptStyled>
                {hayCargosEnColones && (
                  <BillConceptStyled {...propsNumbersSpan}>
                    ₡ {totalLuego > 0 ? FormatterUtils.number(totalLuego?.toFixed(2)) : 0}
                  </BillConceptStyled>
                )}
                {hayCargosEnDolares && (
                  <BillConceptStyled {...propsNumbersSpan}>
                    $ {totalLuegoDolar > 0 ? FormatterUtils.number(totalLuegoDolar?.toFixed(2)) : 0}
                  </BillConceptStyled>
                )}
              </>
            )}
          </BillFlexStyled>
        </Box>
      </BillCardStyled>
      <Modal
        closable={false}
        open={isModalTransferenciaOpen && isTransferActive?.data}
        onOk={handleOkTransferencia}
        confirmLoading={isLoadingFile || isLoadingTransfer}
        cancelText="Cancelar"
        onCancel={!isLoadingFile && !isLoadingTransfer ? handleCancelTransferencia : undefined}
        style={{ maxWidth: 450 }}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            name="model"
            initialValue={dataTransferencia?.fileId}
            rules={[
              {
                required: true,
                message: "El archivo que avale la transferencia es necesario.",
              },
            ]}
            required
          >
            <Upload.Dragger
              {...props}
              onChange={(info) => {
                const { status } = info.file;
                if (status !== "uploading") {
                  onChangeTransferencia(info);
                }
                if (status === "done") {
                  toast.success(`¡${info.file.name} subido exitosamente!`);
                } else if (status === "error") {
                  toast.error(`¡La carga del archivo ${info.file.name} ha fallado!`);
                }
              }}
            >
              <p className="ant-upload-drag-icon">
                <Icon name="" color="#006AEA" />
              </p>
              <p className="ant-upload-text">
                Haz clic o arrastra el archivo a esta área para subirlo
              </p>
              <p className="ant-upload-hint">
                Soporte para carga única o masiva. Prohibida la carga de datos empresariales o
                archivos prohibidos.
              </p>
            </Upload.Dragger>
          </Form.Item>

          <InputContainerStyled>
            <Form.Item
              name="numero"
              required
              label="No. de transferencia"
              initialValue={dataTransferencia?.numero}
              rules={[
                {
                  required: true,
                  message: "Este campo es requerido.",
                },
              ]}
              style={{ marginBottom: 4 }}
            >
              <Input placeholder="No. de transferencia" size="large" />
            </Form.Item>
          </InputContainerStyled>

          <InputContainerStyled>
            <Form.Item
              name="monedaId"
              label="Moneda"
              initialValue={dataTransferencia?.monedaId}
              required
              rules={[
                {
                  required: true,
                  message: "Este campo es requerido.",
                },
              ]}
              style={{ marginBottom: 4 }}
            >
              <Select
                allowClear
                placeholder="Seleccionar moneda"
                size="large"
                options={currencies?.data?.map((ent: ICurrency) => ({
                  label: ent.monedaNombre,
                  value: ent.monedaId,
                }))}
              />
            </Form.Item>
          </InputContainerStyled>
          <InputContainerStyled>
            <Form.Item
              name="montoTransferencia"
              label="Monto"
              initialValue={dataTransferencia?.monto}
              required
              help={<></>}
              rules={[
                {
                  required: true,
                  message: "Este campo es requerido.",
                },
              ]}
              style={{ marginBottom: 4 }}
            >
              <InputNumber
                size="large"
                placeholder="Monto"
                controls={false}
                formatter={(value) => FormatterUtils.number(value)}
                parser={(value) => ParserUtils.number(value)}
              />
            </Form.Item>
          </InputContainerStyled>
          <InputContainerStyled>
            <Form.Item
              name="motivo"
              label="Motivo"
              initialValue={dataTransferencia?.motivo}
              required
              rules={[
                {
                  required: true,
                  message: "Este campo es requerido.",
                },
              ]}
              style={{ marginBottom: 4 }}
            >
              <TextArea size="large" placeholder="Motivo de la transferencia" />
            </Form.Item>
          </InputContainerStyled>

          <InputContainerStyled>
            <Box>
              <Form.Item
                name="entidadFinancieraId"
                label="Entidad financiera"
                initialValue={dataTransferencia?.entidadFinacieraId}
                required
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
                style={{ marginBottom: 4 }}
              >
                <Select
                  allowClear
                  placeholder="Seleccionar entidad financiera"
                  size="large"
                  options={finantialEntities?.data?.map((ent: IFinantialEntity) => ({
                    label: ent.entidadFinancieraNombre,
                    value: ent.entidadFinancieraId,
                  }))}
                />
              </Form.Item>
            </Box>
          </InputContainerStyled>
          <Alert
            message="Nota importante"
            description="Este documento de transferencia estará sujeto a un proceso de revisión que podría tomar hasta 3 días hábiles."
            type="error"
          />
        </Form>
      </Modal>
    </Form>
  );
};

export default Bill;
